import { IState, IUserState } from '@models';
import { createReducer, on } from '@ngrx/store';
import * as userActions from '@store/actions/user';

export const NAMESPACE = 'user';

export const initialState: IUserState = {
  summary: null,
  settings: null,
  isLoading: false,
};

export const UserReducer = createReducer(
  initialState,
  on(
    userActions.SummaryRequest,
    userActions.AgreeToTermsRequest,
    userActions.SettingsRequest,
    userActions.SettingsUpdateRequest,
    userActions.LogoutRequest,
    userActions.UserDeleteRequest,
    (state) => ({
      ...state,
      isLoading: true,
    })
  ),
  on(userActions.SummarySuccess, (state, { summary }) => ({
    ...state,
    summary,
    isLoading: false,
  })),
  on(userActions.AgreeToTermsSuccess, (state) => ({
    ...state,
    summary: {
      ...state.summary,
      AgreedToElua: true,
    },
    isLoading: false,
  })),
  on(
    userActions.SettingsSuccess,
    userActions.SettingsUpdateSuccess,
    (state, { settings }) => ({
      ...state,
      settings,
      isLoading: false,
    })
  ),
  on(
    userActions.UserError,
    userActions.LogoutSuccess,
    userActions.UserDeleteSuccess,
    (state) => ({
      ...state,
      isLoading: false,
    })
  ),
  on(userActions.LogoutSuccess, userActions.LoginSuccess, () => initialState)
);

export default UserReducer;
