import { createFeatureSelector, createSelector } from '@ngrx/store';

import { NAMESPACE } from '../reducers/user';
import { IUserDataSummary, IUserState } from '@models';

export const selectUser = createFeatureSelector<IUserState>(NAMESPACE);

export const selectIsLoading = createSelector(
  selectUser,
  (state: IUserState) => state.isLoading
);

export const selectSummary = createSelector(
  selectUser,
  (state: IUserState) => state.summary
);
export const selectIsAgreedToElua = createSelector(
  selectSummary,
  (summary: IUserDataSummary) => summary?.AgreedToElua || false
);

export const selectIsActiveGuest = createSelector(
  selectSummary,
  (summary: IUserDataSummary) => {
    if (!summary || !summary.IsGuest) return false;
    if (
      summary.Cigars > 0 ||
      summary.Humidors > 0 ||
      summary.Reviews > 0 ||
      summary.Notes > 0 ||
      summary.SocialPosts > 0 ||
      summary.SocialComments > 0
    ) {
      return true;
    }
    return false;
  }
);

export const selectSettings = createSelector(
  selectUser,
  (state: IUserState) => state.settings
);

export const selectShareLocation = createSelector(
  selectUser,
  (state: IUserState) => !!state.settings?.ShareLocation
);
